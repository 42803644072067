import { EmailTemplate as EmailTemplateType } from "app-types/template";
import GlobalWrapper from "components/core/GlobalWrapper";
import React, { useEffect, useState } from "react";
import SEO from "components/core/SEO";
import { EMAIL_TEMPLATES_PATH } from "constants/paths";
import { useTranslation } from "react-i18next";
import {
  BodyContainer,
  EmailTemplateContainer,
  EmailTemplateHeader,
  Subject,
  TemplateContainer,
  TemplateDescription,
} from "templates/templates/email/index.style";
import { useEmailRendering } from "networking/useEmailRendering";
import { PageContainer } from "styles/global.style";
import Fade from "components/core/Animation/extended/Fade";
import { DescriptionCol } from "components/ui/base/ImageHeader/index.style";
import Breadcrumbs from "components/core/Breadcrumbs";
import Heading from "components/core/Typography/Heading";
import SafeLink from "components/core/SafeLink";
import Button from "components/core/Button";
import { CTAColor } from "app-types/color";
import CommunityAuthorBox from "components/ui/extended/CommunityAuthorBox";
import NumberSteps from "components/ui/base/NumberSteps";
import Customers from "components/ui/extended/Customers";
import RegisterForEmailMarketing from "components/ui/extended/RegisterForEmailMarketing";
import VideoPreview from "components/ui/base/VideoPreview";
import FullWidthVideo from "components/ui/base/FullWidthVideo";
import { getEmailTemplateSignupUrl } from "utils/path";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

export interface EmailTemplateProps {
  pageContext: {
    template: EmailTemplateType;
  };
  location: Location;
}

const EmailTemplate = ({ pageContext, location }: EmailTemplateProps) => {
  const { t } = useTranslation();
  const getRendering = useEmailRendering();
  const [html, setHtml] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    getRendering(pageContext.template.community_email_template_id).then(
      (result) => {
        setHtml(result.html);
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{ withCta: false }}
      location={location}
    >
      <SEO
        title={pageContext.template.name}
        description={pageContext.template.description}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: pageContext.template.name,
        }}
        withoutTitleSuffix
      />

      <PageContainer>
        <EmailTemplateHeader>
          <TemplateContainer lg={7} order={1} lgOrder={1}>
            {html ? (
              <EmailTemplateContainer>
                <Subject>{pageContext.template.subject}</Subject>
                <BodyContainer srcDoc={html} />
              </EmailTemplateContainer>
            ) : (
              <></>
            )}
          </TemplateContainer>
          <DescriptionCol lg={5} order={2} lgOrder={2}>
            <Fade direction={"up"} delay={400}>
              <Breadcrumbs
                elements={[
                  {
                    name: "Customerly",
                    path: "/",
                  },
                  {
                    name: "Templates",
                    path: "/templates/",
                  },
                  {
                    name: "Email Marketing",
                    path: `${EMAIL_TEMPLATES_PATH}/`,
                  },
                ]}
              />
            </Fade>

            <Fade direction={"up"} delay={400}>
              <Heading level={1}>{pageContext.template.name}</Heading>
            </Fade>

            <Fade direction={"up"} delay={500}>
              <TemplateDescription>
                {pageContext.template.description}
              </TemplateDescription>
            </Fade>

            <Fade direction={"up"} delay={600}>
              <Button
                as={SafeLink}
                to={getEmailTemplateSignupUrl(
                  pageContext.template.community_email_template_id
                )}
                icon={{ type: "chevron-right" }}
                ctaColor={CTAColor.Yellow}
              >
                {t("templates.templates.email.useTemplate")}
              </Button>
            </Fade>
            <Fade direction={"up"} delay={700}>
              <CommunityAuthorBox author={pageContext.template.author} />
            </Fade>
          </DescriptionCol>
        </EmailTemplateHeader>
      </PageContainer>

      <NumberSteps
        title={t("templates.templates.email.steps.title")}
        elements={[1, 2, 3].map((index) => {
          return {
            title: t(`templates.templates.email.steps.elements.${index}.title`),
            description: t(
              `templates.templates.email.steps.elements.${index}.description`
            ),
          };
        })}
      />

      <FullWidthVideo
        id={"email-template-builder"}
        title={t("templates.templates.email.1.title")}
        description={t("templates.templates.email.1.subtitle")}
        video={{
          path: "pages/email-template-builder/email-template-builder.mp4",
        }}
        cta={{
          text: "Discover Free Email Template Builder",
          link: "/email-template-builder/",
        }}
      />

      <VideoPreview
        id={"save-and-organize-email-templates"}
        title={t("templates.templates.email.2.title")}
        paragraph={t("templates.templates.email.2.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "templates/templates/email/save-and-organize-email-templates.mp4",
          },
        ]}
      />

      <VideoPreview
        id={"newsletter"}
        title={t("templates.templates.email.3.title")}
        paragraph={t("templates.templates.email.3.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "templates/templates/email/email-marketing.mp4",
          },
        ]}
        cta={{
          text: t("templates.templates.email.3.cta"),
          link: "/email-marketing/",
        }}
      />

      <VideoPreview
        id={"automates"}
        title={t("templates.templates.email.4.title")}
        paragraph={t("templates.templates.email.4.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "templates/templates/email/automate-email-marketing.mp4",
          },
        ]}
        cta={{
          text: t("templates.templates.email.4.cta"),
          link: "/marketing-funnel/",
        }}
      />

      <RegisterForEmailMarketing />

      <Customers />

      <BlogPostsBox
        category={"email-marketing"}
        title={"Email Marketing Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};

export default EmailTemplate;
