import client from "networking/client";
import { EmailRendering } from "app-types/template";

export const useEmailRendering = (): ((
  emailId: number
) => Promise<EmailRendering>) => {
  return async (emailId) => {
    return await client.get({
      url: `/public/community/templates/emails/${emailId}/rendering`,
    });
  };
};
