import React, { ReactChild, ReactNode } from "react";
import { Row } from "styled-bootstrap-grid";
import { LargeComponentContainer, PageContainer } from "styles/global.style";
import {
  CTA,
  Description,
  FullWidthVideoCenteredCol,
  FullWidthVideoCol,
  FullWidthVideoContainer,
  StyledFullWidthVideo,
  Video,
  VideoContainer,
} from "./index.style";
import Fade from "components/core/Animation/extended/Fade";
import { Title } from "components/core/Typography/Title";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import { TFunctionResult } from "i18next";
import { graphql, useStaticQuery } from "gatsby";

interface FullWidthVideoProps {
  title: string;
  description?: string | TFunctionResult | ReactChild | ReactNode[];
  maxWidth?: number;
  descriptionMaxWidth?: number;
  id?: string;
  cta?: {
    link: string;
    text: string;
  };
  video?: {
    path: string;
  };
}

export interface FullWidthVideoQueryResult {
  videos: {
    nodes: {
      relativePath: string;
      videoVP9: {
        path: string;
      };
      videoH265: {
        path: string;
      };
    }[];
  };
}

const FullWidthVideo = (props: FullWidthVideoProps) => {
  const { videos }: FullWidthVideoQueryResult = useStaticQuery(graphql`
    query {
      videos: allFile(filter: { sourceInstanceName: { eq: "videos" } }) {
        nodes {
          relativePath
          videoVP9 {
            path
          }
          videoH265 {
            path
          }
        }
      }
    }
  `);

  const video =
    props.video &&
    videos.nodes.find((video) => video.relativePath === props.video?.path);

  const h265Path = video?.videoH265.path;
  const vp9Path = video?.videoVP9.path;

  return (
    <StyledFullWidthVideo id={props.id}>
      <LargeComponentContainer>
        <PageContainer>
          <FullWidthVideoContainer maxWidth={props.maxWidth}>
            <Row>
              <FullWidthVideoCol>
                <Fade direction={"up"}>
                  <Title>{props.title}</Title>
                </Fade>
              </FullWidthVideoCol>
            </Row>
            {props.description && (
              <Row alignItems={"center"}>
                <FullWidthVideoCenteredCol>
                  <Fade direction={"up"}>
                    <Description maxWidth={props.descriptionMaxWidth}>
                      {props.description}
                    </Description>
                  </Fade>
                </FullWidthVideoCenteredCol>
              </Row>
            )}
            {props.cta && (
              <Row alignItems={"center"}>
                <FullWidthVideoCenteredCol>
                  <Fade direction={"up"}>
                    <CTA>
                      <SafeLink to={props.cta.link} title={props.cta.text}>
                        {props.cta.text}
                        <Icon type={"arrow-right"} size={18} />
                      </SafeLink>
                    </CTA>
                  </Fade>
                </FullWidthVideoCenteredCol>
              </Row>
            )}

            {props.video && (
              <Fade direction={"up"} delay={700}>
                <VideoContainer>
                  <Video playsInline autoPlay loop muted>
                    {vp9Path && <source src={vp9Path} type="video/webm" />}
                    {h265Path && <source src={h265Path} type="video/mp4" />}
                  </Video>
                </VideoContainer>
              </Fade>
            )}
          </FullWidthVideoContainer>
        </PageContainer>
      </LargeComponentContainer>
    </StyledFullWidthVideo>
  );
};

export default FullWidthVideo;
