import styled from "styled-components";
import { Col, media, Row } from "styled-bootstrap-grid";

const EmailTemplateContainer = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.baseLighten95Color};
  margin: 0 auto;
  user-select: none;
  min-height: 360px;
  border-top: 2em solid ${(props) => props.theme.baseLighten75Color};
  box-shadow: ${(props) => props.theme.baseBoxShadow};
  position: relative;
  border-radius: 8px;

  :before {
    display: block;
    position: absolute;
    content: "";
    top: -1.25em;
    left: 1em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: ${(props) => props.theme.baseLightColor};
    box-shadow: 0 0 0 2px ${(props) => props.theme.baseLightColor},
      1.5em 0 0 2px ${(props) => props.theme.baseLightColor},
      3em 0 0 2px ${(props) => props.theme.baseLightColor};
  }
`;

const EmailTemplateHeader = styled(Row)`
  padding: 128px 0 0;
`;

const TemplateDescription = styled.p`
  font-size: 18px;
`;

const Subject = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
`;

const BodyContainer = styled.iframe`
  border: none;
  width: 100%;
  min-height: 360px;
  user-select: none;
`;

const TemplateContainer = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;

  ${media.xl`
     padding: 16px 64px;
  `}

  ${media.lg`
     padding: 16px 64px;
  `}

  ${media.xs`
    padding: 16px;
  `}
`;

export {
  EmailTemplateContainer,
  EmailTemplateHeader,
  Subject,
  BodyContainer,
  TemplateContainer,
  TemplateDescription,
};
